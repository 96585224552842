import { useEffect, useRef, useState } from 'react';
import ApproveBalance from '../../components/approve-balance/ApproveBalance';
import BuyNft from '../../components/buy-nft/BuyNft';
import SuccessTransaction from '../../components/success-transaction/SuccessTransaction';
import { loadImage, drawImage, DrawImageCoordinates } from '../../utils/canvas-utility';
import styles from './Card.module.css';

interface CardInterface {
  brokenDoll: string;
}

const assetUrl = 'https://dgapg589p5zmg.cloudfront.net/broken-dolls/';

const Card = ({ brokenDoll }: CardInterface) => {
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [head, chest, leftArm, rightArm, leftLeg, rightLeg] = brokenDoll.split('-');

  useEffect(() => {
    const drawBrokenDoll = async () => {
      if (canvasRef.current) {
        const dollCoordinates: DrawImageCoordinates = [555, 200, 1702 / 4.5, 4744 / 4.5];
        const bg = await loadImage('/assets/dolls/background.png');
        drawImage(bg, canvasRef.current);
        const images = await Promise.all([
          loadImage(`${assetUrl}${rightArm}_brazoderecho.png`),
          loadImage(`${assetUrl}${leftArm}_brazoizquierdo.png`),
          loadImage(`${assetUrl}${rightLeg}_piernaderecha.png`),
          loadImage(`${assetUrl}${leftLeg}_piernaizquierda.png`),
          loadImage(`${assetUrl}${chest}_tronco.png`),
          loadImage(`${assetUrl}${head}_cabeza.png`)
        ]);
        images.forEach(image => drawImage(image, canvasRef.current, dollCoordinates));
      }
    };
    drawBrokenDoll();
  }, []);

  const toggleApprovalModal = () => {
    setShowApproveModal(state => !state);
  };

  const toggleBuyModal = () => {
    setShowBuyModal(state => !state);
  };

  const toggleSuccessModal = () => {
    setShowSuccessModal(state => !state);
  };

  const approveTransaction = () => {
    toggleApprovalModal();
    toggleBuyModal();
  };

  const completeTransaction = () => {
    toggleBuyModal();
    toggleSuccessModal();
  };

  return (
    <>
      <div className={styles.card}>
        <div className={styles.header}>{brokenDoll}</div>
        <div className="content">
          <canvas width="1500" height="1500" ref={canvasRef} className={styles.image}></canvas>
        </div>
        <div className="footer">
          <button className={styles.button} onClick={toggleApprovalModal}>
            150 CGT
          </button>
        </div>
      </div>
      {showApproveModal && <ApproveBalance onClose={toggleApprovalModal} onApprove={approveTransaction} />}
      {showBuyModal && <BuyNft onClose={toggleBuyModal} onBuy={completeTransaction} />}
      {showSuccessModal && <SuccessTransaction onClose={toggleSuccessModal} />}
    </>
  );
};

export default Card;
