import styles from './Header.module.css';

function Header({ onMenuClick }: { onMenuClick: () => void }) {
  return (
    <div className={styles.container}>
      <span className={styles.menu}></span>
      <img className={styles.logo} src="/assets/embla-logo.png" alt="Embla Logo" />
      <button className={styles.menu} onClick={onMenuClick}>
        <img src="/assets/inventory-icon.svg" alt="icon" />
      </button>
    </div>
  );
}

export default Header;
