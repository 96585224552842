export const signIn = async (publicAddress?: string | null, signature?: string | null) => {
  const url = 'https://6399kkay5c.execute-api.us-east-1.amazonaws.com/serverless_lambda_stage/sign-in';
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({ publicAddress, signature })
    });
    const body = await response.json();
    if (response.status !== 200) {
      throw body;
    }
    return body.data;
  } catch (error) {
    throw error;
  }
};
