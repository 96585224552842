import { BrowserRouter, Route, Routes } from 'react-router-dom';
import styles from './App.module.css';
import Header from './components/header/Header';
import Sidebar from './components/sidebar/Sidebar';
import Dolls from './modules/dolls/Dolls';
import Gauntlets from './modules/gauntlets/Gauntlets';

function App() {
  return (
    <BrowserRouter>
      <main className={styles.container}>
        <Sidebar />
        <Routes>
          <Route path="inventory/broken-dolls" element={<Dolls />} />
          <Route path="inventory/gauntlets" element={<Gauntlets />} />
        </Routes>
      </main>
    </BrowserRouter>
  );
}

export default App;
