import Input from '../components/Input';
import Filters from '../components/Filters';
import Card from '../components/Card';

import styles from '../Layout.module.css';
import { useBrokenDoll } from './broken-dolls.hook';

function Dolls() {
  const { brokenDolls, elements, search } = useBrokenDoll();

  return (
    <div className={styles.content}>
      <nav className={styles.nav}>
        <Input type="search" placeholder="Search" onChange={search}></Input>
        <Filters></Filters>
      </nav>
      <section className={styles.list} ref={elements.contentRef}>
        {brokenDolls.map(brokenDoll => (
          <Card key={brokenDoll} brokenDoll={brokenDoll}></Card>
        ))}
        <div className={styles.listend} ref={elements.endElRef}></div>
      </section>
    </div>
  );
}

export default Dolls;
