import { MouseEvent, MouseEventHandler, ReactEventHandler, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import { web3, web3Sign } from '../../utils/web3';
import Header from '../header/Header';
import SignUp from '../sign-up/SignUp';
import styles from './Sidebar.module.css';
import { signIn } from './sign-in-api';

const routes = new Map([
  ['/', '-24.5rem'],
  ['/marketplace', '6.5rem'],
  ['/inventory/gauntlets', '-3.5rem'],
  ['/inventory/broken-dolls', '-3.5rem']
]);

function Sidebar() {
  const [menuActiveClass, setMenuActiveClass] = useState<null | string>(null);
  const [selectionPos, setSelectionPos] = useState<string>('-20.5rem');
  const [showSignUp, setShowSignUp] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<string | null>(null);

  const { pathname } = useLocation();

  useEffect(() => {
    setSelectionPos(routes.get(pathname) || '-20.5rem');
  }, [pathname]);

  const handleMenuState = () => {
    setMenuActiveClass(state => (state ? null : styles['menu-active']));
  };

  const showSignupAndHideMenu = async () => {
    handleMenuState();
    if (typeof ethereum === 'undefined') {
      return alert('You must have Metamask installed and configured to login');
    }
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
    const publicAddress = accounts[0];
    try {
      await signIn(publicAddress);
    } catch (error: any) {
      if (error.code === 1) {
        setShowSignUp(true);
      }
      if (error.code === 2) {
        alert('Bad credentiales, please try again!');
      }
      if (error.code === 3) {
        handleSignIn(error.data.nonce);
      }
    }
  };

  const handleSignIn = async (nonce: number) => {
    const publicAddress = await web3.eth.getCoinbase();
    const msg = `I am signing my one-time nonce: ${nonce}`;
    const signature = await web3Sign(publicAddress, msg);
    const { token } = await signIn(publicAddress, signature);
    setToken(token);
    const decoded = jwt_decode(token) as any;
    setUser(decoded.user);
  };

  const finishSignUp = (nonce?: number) => {
    setShowSignUp(false);
    if (nonce) {
      handleSignIn(nonce);
    }
  };

  const disabledEvent = (e: unknown) => (e as Event).preventDefault();

  return (
    <>
      <Header onMenuClick={handleMenuState} />
      <div className={`${styles.container} ${menuActiveClass}`}>
        <div>
          <img className={styles.logo} src="/assets/embla-logo.png" alt="Embla Logo" />
          <ul className={styles.list}>
            <img
              style={{ transform: `translateY(${selectionPos})` }}
              className={styles.selector}
              src="/assets/active-link.png"
              alt="selection"
            />
            <li className={styles.item}>
              <Link to="/" onClick={handleMenuState}>
                <img src="/assets/home-icon.svg" alt="icon" /> HOME
              </Link>
            </li>
            <li className={styles.item}>
              <Link to="/explore" className="link-disabled" onClick={disabledEvent}>
                <img src="/assets/explore-icon.svg" alt="icon" /> EXPLORE
              </Link>
            </li>
            <li className={styles.item}>
              <Link to="/inventory/broken-dolls" onClick={handleMenuState}>
                <img src="/assets/inventory-icon.svg" alt="icon" /> INVENTORY
              </Link>
            </li>
            <li className={styles.item}>
              <Link to="/marketplace" className="link-disabled" onClick={disabledEvent}>
                <img src="/assets/marketplace-icon.svg" alt="icon" /> MARKETPLACE
              </Link>
            </li>
            <li className={styles.item}>
              <Link to="/leaderboard" className="link-disabled" onClick={disabledEvent}>
                <img src="/assets/leaderboard-icon.svg" alt="icon" /> LEADERBOARD
              </Link>
            </li>
          </ul>
        </div>
        {!token ? (
          <button className={styles['login-button']} onClick={showSignupAndHideMenu}>
            Login With Metamask
            <img src="/assets/metamask-logo.png" alt="Metamask" />
          </button>
        ) : (
          <div className={styles['login-user']}>
            {user}
            <img src="/assets/signed-in-icon.png" alt="user" />
          </div>
        )}
      </div>
      {showSignUp && <SignUp onClose={finishSignUp} />}
    </>
  );
}

export default Sidebar;
