import { createPortal } from 'react-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { isPhoneNumber } from '../../utils/numbers';
import { web3 } from '../../utils/web3';

import styles from '../Modal.module.css';
import { signUp } from './sign-up-api';

type Inputs = {
  name: string;
  age: string;
  discordUser: string;
  email: string;
  phone: string;
};

function SignUp({ onClose }: { onClose: (nonce?: number) => void }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<Inputs>({ mode: 'onChange' });
  const onSubmit: SubmitHandler<Inputs> = async data => {
    const publicAddress = await web3.eth.getCoinbase();
    try {
      const user = await signUp({ ...data, publicAddress });
      onClose(user.nonce);
    } catch (error) {
      console.log(error);
    }
  };

  const validations = { required: true };
  console.log(errors, isValid);
  const containerEl = document.getElementById('overlay-root');
  return containerEl ? (
    createPortal(
      <div className={styles.container}>
        <div className={styles.backdrop} onClick={() => onClose()}></div>
        <form className={styles.modal} onSubmit={handleSubmit(onSubmit)}>
          <section className={styles.field}>
            <label>Fullname</label>
            <input type="text" placeholder="Peter Parker" {...register('name', validations)} />
          </section>
          <section className={styles.field}>
            <label>Age</label>
            <input
              max={100}
              min={18}
              className={styles['numeric-input']}
              type="number"
              placeholder="18"
              {...register('age', validations)}
            />
          </section>
          <section className={styles.field}>
            <label>Discord User #</label>
            <input type="text" placeholder="Peter#1234" {...register('discordUser', validations)} />
          </section>
          <section className={styles.field}>
            <label>email</label>
            <input type="email" placeholder="peter@example.com" {...register('email', validations)} />
          </section>
          <section className={styles.field}>
            <label>Phone Number</label>
            <input
              type="tel"
              placeholder="+1 123456789"
              {...register('phone', { ...validations, validate: isPhoneNumber })}
            />
          </section>
          <div className={styles.message}>** All fields are required **</div>
          <button className={styles.submit} disabled={!isValid}>
            Sign Up
          </button>
        </form>
      </div>,
      containerEl
    )
  ) : (
    <div>Error loading sign up</div>
  );
}

export default SignUp;
