import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import useIntersectionObserver from '../../utils/intersection-observer-hook';
import { fetchBrokenDolls } from './broken-dolls-api';

export const useBrokenDoll = () => {
  const [brokenDolls, setBrokenDolls] = useState<string[]>([]);
  const [lastItem, setLastItem] = useState<number | null>(null);
  const [term, setTerm] = useState<string | null>(null);

  const endElRef = useRef(null);
  const contentRef = useRef(null);

  const entry = useIntersectionObserver(endElRef, {
    root: window.outerWidth < 768 ? null : contentRef.current,
    rootMargin: '50px',
    threshold: 1
  });

  const debouncedSearch = useCallback(
    debounce(async (query?: string) => {
      if (query && query.length < 3) return;
      setLastItem(null);
      setTerm(query || null);
      const data = await fetchBrokenDolls(query);
      setBrokenDolls(data.Items);
      setLastItem(data?.LastEvaluatedKey?.id);
    }, 1000),
    [lastItem]
  );

  const debouncedGetMoreResults = useCallback(
    debounce(async (query?: string, lastKey?: number) => {
      if (!lastKey && !lastItem) return;
      const data = await fetchBrokenDolls(query || term, lastKey || lastItem);
      setBrokenDolls(state => [...state, ...data.Items]);
      setLastItem(data?.LastEvaluatedKey?.id);
    }, 1000),
    [term, lastItem]
  );

  const search = (event: any) => {
    debouncedSearch(event.target.value);
  };

  useEffect(() => {
    fetchBrokenDolls().then(data => {
      setBrokenDolls(state => [...state, ...data.Items]);
      setLastItem(data.LastEvaluatedKey.id);
    });
  }, []);

  useEffect(() => {
    if (entry?.isIntersecting) {
      debouncedGetMoreResults();
    }
  }, [entry?.isIntersecting]);

  return { brokenDolls, elements: { endElRef, contentRef }, search };
};
