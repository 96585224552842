import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Filters.module.css';

const Filters = () => {
  const { pathname } = useLocation();

  const getActiveClass = (path: string) => {
    return pathname === `/inventory/${path}` ? styles.tabactive : '';
  };
  return (
    <ul className={styles.nav}>
      <li className={styles.item}>
        <Link to="/inventory/broken-dolls">
          <button className={`${styles.button} ${getActiveClass('broken-dolls')}`}>Broken Dolls</button>
        </Link>
      </li>
      <li className={styles.item}>
        <Link to="/inventory/gauntlets">
          <button className={`${styles.button} ${getActiveClass('gauntlets')}`}>Gauntlets</button>
        </Link>
      </li>
      {/*<li className={styles.item}>
        <button className={styles.button}>Category 3</button>
      </li>
      <li className={styles.item}>
        <button className={styles.button}>Category 4</button>
  </li>*/}
    </ul>
  );
};

export default Filters;
