export type DrawImageCoordinates =
  | [dx: number, dy: number]
  | [dx: number, dy: number, dw: number, dh: number]
  | [sx: number, sy: number, sw: number, sh: number, dx: number, dy: number, dw: number, dh: number];

export const loadImage = (url: string): Promise<HTMLImageElement> => {
  return new Promise(res => {
    const backgroundImg = new Image();
    backgroundImg.src = url;
    backgroundImg.onload = () => {
      return res(backgroundImg);
    };
  });
};

export const drawImage = (
  image: HTMLImageElement,
  canvas: HTMLCanvasElement | null,
  coordinates: DrawImageCoordinates = [0, 0]
): void => {
  if (canvas) {
    const ctx = canvas.getContext('2d');
    const drawCoordinates: [number, number] = coordinates as [number, number];
    ctx?.drawImage(image, ...drawCoordinates);
  }
};
