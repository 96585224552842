import { createPortal } from 'react-dom';

import modalStyles from '../Modal.module.css';
import styles from './SuccessTransaction.module.css';

function SuccessTransaction({ onClose }: { onClose: () => void }) {
  const containerEl = document.getElementById('overlay-root');
  return containerEl ? (
    createPortal(
      <div className={modalStyles.container}>
        <div className={modalStyles.backdrop} onClick={onClose}></div>
        <div className={modalStyles.modal}>
          <button className={styles.close} onClick={onClose}>
            X
          </button>
          <div className={styles.content}>
            <div>Compra Exitosa!</div>
            <img src="/assets/embla-nft.png" alt="Embla" />
          </div>
        </div>
      </div>,
      containerEl
    )
  ) : (
    <div>Error loading buying nft flow</div>
  );
}

export default SuccessTransaction;
