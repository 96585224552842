import { createPortal } from 'react-dom';

import modalStyles from '../Modal.module.css';
import styles from './ApproveBalance.module.css';

function ApproveBalance({ onClose, onApprove }: { onClose: () => void; onApprove: () => void }) {
  const containerEl = document.getElementById('overlay-root');
  return containerEl ? (
    createPortal(
      <div className={modalStyles.container}>
        <div className={modalStyles.backdrop} onClick={onClose}></div>
        <div className={modalStyles.modal}>
          <button className={styles.close} onClick={onClose}>
            X
          </button>
          <div className={styles.steps}>
            <div className={`${styles.step} ${styles.active}`}>
              1<span>Approve balance</span>
            </div>
            <div className={`${styles.step}`}>
              2<span>Buy NFT</span>
            </div>
          </div>
          <div className={`${styles.separator}`}></div>
          <div className={`${styles.separator} ${styles.short}`}></div>
          <div className={styles.content}>
            Para poder efectuar la compra del <span className={styles.highlight}>"NFT Embla 001"</span> se necesita
            aprobar saldo del token CGT, ahora mismo solo tienes 0 CGT disponible y necesitas tener 150 CGT.
          </div>
          <button className={`${modalStyles.submit} ${styles.submit}`} onClick={onApprove}>
            Approve purchase by 150 CGT
          </button>
        </div>
      </div>,
      containerEl
    )
  ) : (
    <div>Error loading buying nft flow</div>
  );
}

export default ApproveBalance;
