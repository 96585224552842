import styles from './GauntletCard.module.css';

export interface Gauntlet {
  attack: string;
  description: string;
  element: string;
  id: string;
  name: string;
  rarity: string;
  rarity_detail: string;
}

export const attackMap = new Map([
  ['gun', 'sword'],
  ['machine-gun', 'daggers'],
  ['shot-gun', 'axe']
]);

export const attackPathMap = new Map([
  ['gun', '1.+SWORD'],
  ['machine-gun', '2.+DAGGERS'],
  ['shot-gun', '3.+AXE']
]);
export const rarityPathMap = new Map([
  ['plane', '1.+PLANES'],
  ['tribe', '2.+TRIBES'],
  ['creature', '3.+CREATURES'],
  ['deity', '4.+DEITY']
]);

export interface GauntletCardProps {
  gauntlet: Gauntlet;
}

const toCapitalCase = function (word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

const GauntletCard = ({ gauntlet }: GauntletCardProps) => {
  const image = `${attackPathMap.get(gauntlet.attack)}/${rarityPathMap.get(gauntlet.rarity)}/${toCapitalCase(
    gauntlet.name.replace("'", '_')
  )}`;
  return (
    <div className={styles.card} style={{ border: `0.4rem solid var(--${gauntlet.rarity}-rarity)` }}>
      <div className={styles.header}>{toCapitalCase(gauntlet.name)}</div>
      <div className="content">
        <img
          className={styles.image}
          src={`https://dgapg589p5zmg.cloudfront.net/gauntlets/${image}.png`}
          loading="lazy"
          alt={`${gauntlet.name} image`}
        />
        <div className={styles.description}>{gauntlet.description}</div>
        <div className={styles.stats} style={{ background: `var(--${gauntlet.rarity}-gradient)` }}>
          <img className={styles.bg} src={`/assets/rarities/${gauntlet.rarity}-bg.png`} alt="rarity background" />
          <div className={styles.stat}>
            <img src="/assets/gauntlet-types/right-arm.png" alt="gauntlet type" />
            <span>Right-arm</span>
          </div>
          <div>
            <div className={`${styles.stat} ${styles.rarity}`}>
              <img src={`/assets/rarities/${gauntlet.rarity}.png`} alt="rarity" />
              <span>{gauntlet.rarity}</span>
            </div>
            <div className={styles.stat}>
              <img src={`/assets/shoot-types/${attackMap.get(gauntlet.attack)}.png`} alt="gauntlet type" />
              <span>{attackMap.get(gauntlet.attack)}</span>
            </div>
          </div>
          <div className={styles.stat}>
            <img src={`/assets/elements/${gauntlet.element}.png`} alt="element" />
            <span>{gauntlet.element}</span>
          </div>
        </div>
        <span className={styles.id}>ID: {gauntlet.id}</span>
      </div>
      <div className="footer">
        <button className={styles.button}>150 CGT</button>
      </div>
    </div>
  );
};

export default GauntletCard;
