import { createPortal } from 'react-dom';
import Input from '../../modules/components/Input';

import modalStyles from '../Modal.module.css';
import styles from './BuyNft.module.css';

function BuyNft({ onClose, onBuy }: { onClose: () => void; onBuy: () => void }) {
  const containerEl = document.getElementById('overlay-root');
  return containerEl ? (
    createPortal(
      <div className={modalStyles.container}>
        <div className={modalStyles.backdrop} onClick={onClose}></div>
        <div className={modalStyles.modal}>
          <button className={styles.close} onClick={onClose}>
            X
          </button>
          <div className={styles.steps}>
            <div className={`${styles.step} ${styles.active}`}>
              <img src="/assets/check-icon.svg" alt="check" />
              <span>Approve balance</span>
            </div>
            <div className={`${styles.step}`}>
              2<span>Buy NFT</span>
            </div>
          </div>
          <div className={`${styles.separator}`}></div>
          <div className={`${styles.separator} ${styles.short}`}></div>
          <div className={styles.content}>
            <div>Buying Details</div>
            <div className={styles.detail}>
              <div>NFT</div>
              <div>Embla 001</div>
            </div>
            <div className={styles.detail}>
              <div>Final Cost</div>
              <div className={styles.highlight}>150 CGT</div>
            </div>
            <Input placeholder="Ingrese su código promocional"></Input>
            <div className={styles.note}>
              Si cuentas con alguna membresía activa que aplique descuento a la que quieres compra podrías usarla para
              reducir el coste.
            </div>
          </div>
          <button className={`${modalStyles.submit} ${styles.submit}`} onClick={onBuy}>
            Buy NFT
          </button>
        </div>
      </div>,
      containerEl
    )
  ) : (
    <div>Error loading buying nft flow</div>
  );
}

export default BuyNft;
