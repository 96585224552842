import Input from '../components/Input';
import Filters from '../components/Filters';
import GauntletCard from './GauntletCard';

import styles from '../Layout.module.css';
import { useGauntlet } from './gauntlets-hook';

function Gauntlets() {
  const { gauntlets, elements, search } = useGauntlet();

  return (
    <div className={styles.content}>
      <nav className={styles.nav}>
        <Input type="search" placeholder="Search" onChange={search}></Input>
        <Filters></Filters>
      </nav>
      <section className={styles.list} ref={elements.contentRef}>
        {gauntlets.map(gauntlet => (
          <GauntletCard key={gauntlet.id} gauntlet={gauntlet}></GauntletCard>
        ))}
        <div className={styles.listend} ref={elements.endElRef}></div>
      </section>
    </div>
  );
}

export default Gauntlets;
