import { FormEventHandler } from 'react';
import styles from './Input.module.css';

interface InputProps {
  type?: string;
  placeholder?: string;
  onChange?: FormEventHandler<HTMLInputElement>;
}

function Input({ type = 'text', placeholder = '', onChange }: InputProps) {
  return (
    <div className={styles.container}>
      {type === 'search' && <img src="/assets/search-icon.svg" alt="search icon" />}
      <input className={styles.input} type="text" placeholder={placeholder} onChange={onChange} />
    </div>
  );
}

export default Input;
