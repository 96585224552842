import Web3 from 'web3';

export const web3 = typeof ethereum !== 'undefined' ? new Web3(ethereum) : new Web3();

export const web3Sign = (publicAddress: string, msg: string): Promise<string> =>
  new Promise((res, rej) =>
    web3.eth.personal.sign(
      web3.utils.fromUtf8(msg),
      publicAddress.toLowerCase(),
      null as unknown as string,
      (err, signature) => {
        if (err) return rej(err);
        res(signature);
      }
    )
  );
